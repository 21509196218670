var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("read", _vm.abilityRequired)
    ? _c(
        "div",
        [
          _c(
            "b-card",
            { staticClass: "mb-0", attrs: { "no-body": "" } },
            [
              _c(
                "div",
                { staticClass: "m-2" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-flex align-items-center justify-content-start mb-1 mb-md-0",
                          attrs: { cols: "12", md: "6" }
                        },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                variant: "primary",
                                disabled: this.$store.state.onboarding
                                  .fetchingList
                              },
                              on: { click: _vm.refreshList }
                            },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "RefreshCcwIcon" }
                              }),
                              _c("span", { staticClass: "text-nowrap ml-1" }, [
                                _vm._v("Refresh")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { staticClass: "mt-1", attrs: { cols: "12", md: "6" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-end"
                            },
                            [
                              _c("b-form-input", {
                                staticClass: "d-inline-block mr-1",
                                attrs: { placeholder: "Search..." },
                                model: {
                                  value: _vm.searchQuery,
                                  callback: function($$v) {
                                    _vm.searchQuery = $$v
                                  },
                                  expression: "searchQuery"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("b-table", {
                ref: "refListTable",
                staticClass: "position-relative",
                attrs: {
                  items: _vm.fetchListItems,
                  responsive: "",
                  fields: _vm.tableColumns,
                  "primary-key": "username",
                  "sort-by": _vm.sortBy,
                  "sort-desc": _vm.isSortDirDesc,
                  busy: _vm.isBusy,
                  "force-refresh": _vm.forceRefresh
                },
                on: {
                  "update:sortBy": function($event) {
                    _vm.sortBy = $event
                  },
                  "update:sort-by": function($event) {
                    _vm.sortBy = $event
                  },
                  "update:sortDesc": function($event) {
                    _vm.isSortDirDesc = $event
                  },
                  "update:sort-desc": function($event) {
                    _vm.isSortDirDesc = $event
                  },
                  "update:busy": function($event) {
                    _vm.isBusy = $event
                  },
                  "update:forceRefresh": function($event) {
                    _vm.forceRefresh = $event
                  },
                  "update:force-refresh": function($event) {
                    _vm.forceRefresh = $event
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "cell(user)",
                      fn: function(data) {
                        return [
                          _c(
                            "b-media",
                            {
                              attrs: { "vertical-align": "center" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "aside",
                                    fn: function() {
                                      return [
                                        _c("b-avatar", {
                                          attrs: {
                                            size: "32",
                                            src: data.item.avatar,
                                            text: _vm.avatarText(
                                              data.item.fullName
                                            ),
                                            variant:
                                              "light-" +
                                              _vm.resolveUserRoleVariant(
                                                data.item.role
                                              ),
                                            to: {
                                              name: "apps-onboarding-manage",
                                              params: {
                                                username: data.item.username
                                              }
                                            }
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _c(
                                "b-link",
                                {
                                  staticClass:
                                    "font-weight-bold d-block text-nowrap",
                                  attrs: {
                                    to: {
                                      name: "apps-onboarding-manage",
                                      params: { username: data.item.username }
                                    }
                                  }
                                },
                                [_vm._v(" " + _vm._s(data.item.fullName) + " ")]
                              ),
                              _c("small", { staticClass: "text-muted" }, [
                                _vm._v("@" + _vm._s(data.item.username))
                              ])
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "cell(phone)",
                      fn: function(data) {
                        return [
                          _c("div", { staticClass: "text-nowrap" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.formatPhoneNumber(data.item.phone)) +
                                " "
                            )
                          ])
                        ]
                      }
                    },
                    {
                      key: "cell(role)",
                      fn: function(data) {
                        return [
                          _c(
                            "div",
                            { staticClass: "text-nowrap" },
                            [
                              _c("feather-icon", {
                                staticClass: "mr-50",
                                class:
                                  "text-" +
                                  _vm.resolveUserRoleVariant(data.item.role),
                                attrs: {
                                  icon: _vm.resolveUserRoleIcon(data.item.role),
                                  size: "18"
                                }
                              }),
                              _c(
                                "span",
                                {
                                  staticClass: "align-text-top text-capitalize"
                                },
                                [_vm._v(_vm._s(data.item.role))]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "cell(status)",
                      fn: function(data) {
                        return [
                          _c(
                            "b-badge",
                            {
                              staticClass: "text-capitalize",
                              attrs: {
                                pill: "",
                                variant:
                                  "light-" +
                                  _vm.resolveUserStatusVariant(data.item.status)
                              }
                            },
                            [_vm._v(" " + _vm._s(data.item.status) + " ")]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1372976478
                )
              }),
              _c(
                "div",
                { staticClass: "mx-2 mb-2 mt-2" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-flex align-items-center justify-content-start mb-1 mb-md-0",
                          attrs: { cols: "12", md: "6" }
                        },
                        [
                          _c("label", [_vm._v("Show")]),
                          _c("v-select", {
                            staticClass:
                              "per-page-selector d-inline-block mx-50",
                            attrs: {
                              dir: _vm.$store.state.appConfig.isRTL
                                ? "rtl"
                                : "ltr",
                              options: _vm.perPageOptions,
                              clearable: false
                            },
                            model: {
                              value: _vm.perPage,
                              callback: function($$v) {
                                _vm.perPage = $$v
                              },
                              expression: "perPage"
                            }
                          }),
                          _c("label", [_vm._v("entries")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-flex align-items-center justify-content-center justify-content-sm-start",
                          attrs: { cols: "12", sm: "6" }
                        },
                        [
                          _c("span", { staticClass: "text-muted" }, [
                            _vm._v(
                              "Showing " +
                                _vm._s(_vm.dataMeta.from) +
                                " to " +
                                _vm._s(_vm.dataMeta.to) +
                                " of " +
                                _vm._s(_vm.dataMeta.of) +
                                " entries"
                            )
                          ])
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-flex align-items-center justify-content-center justify-content-sm-end",
                          attrs: { cols: "12", sm: "6" }
                        },
                        [
                          _c("b-pagination", {
                            staticClass: "mb-0 mt-1 mt-sm-0",
                            attrs: {
                              "total-rows": _vm.totalItems,
                              "per-page": _vm.perPage,
                              "first-number": "",
                              "last-number": "",
                              "prev-class": "prev-item",
                              "next-class": "next-item"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "prev-text",
                                  fn: function() {
                                    return [
                                      _c("feather-icon", {
                                        attrs: {
                                          icon: "ChevronLeftIcon",
                                          size: "18"
                                        }
                                      })
                                    ]
                                  },
                                  proxy: true
                                },
                                {
                                  key: "next-text",
                                  fn: function() {
                                    return [
                                      _c("feather-icon", {
                                        attrs: {
                                          icon: "ChevronRightIcon",
                                          size: "18"
                                        }
                                      })
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              1308952388
                            ),
                            model: {
                              value: _vm.currentPage,
                              callback: function($$v) {
                                _vm.currentPage = $$v
                              },
                              expression: "currentPage"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }