import { render, staticRenderFns } from "./itemsList.vue?vue&type=template&id=ca553004&scoped=true&"
import script from "./itemsList.vue?vue&type=script&lang=js&"
export * from "./itemsList.vue?vue&type=script&lang=js&"
import style0 from "./itemsList.vue?vue&type=style&index=0&id=ca553004&lang=scss&scoped=true&"
import style1 from "./itemsList.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca553004",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/jasontaylor/Sites/Cancom_Secure_Portal_2024/cancom-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ca553004')) {
      api.createRecord('ca553004', component.options)
    } else {
      api.reload('ca553004', component.options)
    }
    module.hot.accept("./itemsList.vue?vue&type=template&id=ca553004&scoped=true&", function () {
      api.rerender('ca553004', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/apps/onboarding/list/itemsList.vue"
export default component.exports